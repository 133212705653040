#banner{
	position:relative;
	min-height: 400px;

	@include screen-below(map-get($breakpoints, full)) {
		background-image:    url("../images/banner--1200.jpg");
		background-position: top;
		background-size:     cover;
		background-repeat:   no-repeat;
	}

	&__img{
		@include screen-below(map-get($breakpoints, full)) {
			display:none;
		}
	}

	&__content{
		padding: 2.4em;
		position:absolute;
		top:0;
		left:0;
		z-index: 100;
		@include screen-below(map-get($breakpoints, full)) {
			  position:relative;
				padding:1.5em;
		}
		@include screen-below(map-get($breakpoints, medium)) {

		}
		@include screen-below(map-get($breakpoints, x-small)) {
			padding:1em;
		}
	}

	&__logo{
		position:relative;
		max-width:20%;
		min-width:200px;
		margin-bottom:4.2em;
		@include screen-below(map-get($breakpoints, full)) {
			margin-bottom:2.5em;
		}
		@include screen-below(map-get($breakpoints, large)) {
			margin-bottom:2em;
		}
		@include screen-below(map-get($breakpoints, x-small)) {
			margin-bottom:1em;
		}
	}

	&__card{
		padding:2.3rem;
		position:relative;
		max-width:40%;

		@include screen-below(map-get($breakpoints, full)) {
			padding:1.3em;
			max-width:35%;
		}

		@include screen-below(map-get($breakpoints, small)) {
			max-width:45%;
			padding:1em;
		}

		@include screen-below(map-get($breakpoints, x-small)) {
			max-width:50%;
			padding:0.5em;
		}

		h1{
			font-size: 1.5em;
			@include screen-below(map-get($breakpoints, x-small)) {
				font-size: 1.1em;
			}
		}
		p {
			margin-top: 0.58em;
			margin-bottom:0.58em;
			@include screen-below(map-get($breakpoints, x-small)) {
				font-size: 0.85em;
			}
		}
	}

	&__link{
		text-transform: uppercase;
	}

}

#feature-cards {
	margin-top:0em;
	@include screen-below(map-get($breakpoints, medium)) {
		padding-right:2em;
		padding-left:2em;
	}
	@include screen-below(map-get($breakpoints, small)) {
		padding-right:1em;
		padding-left:1em;
	}
	@include screen-below(map-get($breakpoints, x-small)) {
		padding-right:0;
		padding-left:0;
	}
}

.feature-card{

	display:block;
	max-width:600px;
	margin:auto;

	@include screen-below(map-get($breakpoints, small)) {
		padding-right:1em;
		padding-left:1em;
	}

	&:hover .feature-card__image-container{
		background-color: map-get($colors, am-blue);
	}

	&:hover h2{
		color: map-get($colors, am-orange);
	}

	&__image{
		&-container{
			transition: background-color 200ms, border-bottom 200ms;
			padding:0.3em;
		}
		display:block;
		@include aspect-ratio(2000,800);
		background-position: center;
		background-size:     cover;
		background-repeat:   no-repeat;
		&--clinic{
			background-image:    url("../images/clinical_massage--600.jpg");
		}
		&--supplies{
			background-image:    url("../images/med_bookshops--600.jpg");
		}
		&--courses{
			background-image:    url("../images/edu_courses--600.jpg");
		}


	}
	&__text{
		transition: color 200ms, border-bottom 200ms;
		text-align: center;
		margin-top:1.2em;
	}
}
