@mixin clearfix {
  &:after {
    display: table;
    clear: both;
    content: "";
  }
}

.clearfix {
  @include clearfix;
}
