.am-form {

	&__label-info{
		font-size: 0.75rem;
	}

	fieldset {
		border: 0;
		padding: 0;
		padding-top:1rem;
		padding-bottom:1rem;
	}

	legend {
		background-color: map-get($colors, 'am-blue');
		color:white;
		display:block;
		padding: 1rem;
		width:100%;
		text-transform: uppercase;
	}

	.btn {
		text-transform: uppercase;
		padding: 1rem;
	}

	&__checkboxes{

		label{
			font-style: italic;
		}

		.checkbox{
			width: 1.3em;
			height: 1.3em;
			background-color: white;
			border-radius: 4px;
			border: 1px solid #ddd;
			-webkit-appearance: none;
			outline: none;
			cursor: pointer;
			display: table;

			&:checked{
				&:before{
					color:map_get($colors,'am-blue');
					content: "✓";
					display: table-cell;
					vertical-align: middle;
					text-align: center;
				}
			}
		}

		&--centered {
			text-align: center;

			label {
				padding:1rem;
			}
			.checkbox {
				margin-top:1rem;
				margin-left: auto;
				margin-right: auto;
				vertical-align: middle;
			}
		}
		&--right{
			label{
				float:right;
				margin-right:1rem;
			}
			.checkbox{
				float:right;
			}
		}

	}

}