/**
 * Colours
 */

$grays: (
	readable-grey : #686868,
	dark-grey  : #3A3A3C,

);

$colors: (
	am-blue : #261C58,
	am-orange : #D55A32,
);

/**
 * Typography
 */

$font-family-helvetica: 'helvetica_neue_lt', Helvetica Neue, Helvetica, Arial;

$font-family-primary: $font-family-helvetica;
$font-family-headings: $font-family-helvetica;

$font-weight-body: 400;
$font-weight-headings: 400;

$line-height-default: 1.25;

$body-font-sizes: (
											null : 100%,
											small : 105%,
											medium : 118.8%,
											large : 131.3%,
											full : 143.8%,
									);

$font-size-small: 0.85em;
$font-size-large: 1.125em;

$h1-font-sizes: (
										null : (2.5em, 1.1)
								);

$h2-font-sizes: (
										null : (2em, 1.1)
								);

$h3-font-sizes: (
										null : (1.625em, 1.1)
								);

$h4-font-sizes: (
										null : (1.45em, 1.1)
								);

$h5-font-sizes: (
										null : (1.325em, 1.1)
								);

$h6-font-sizes: (
										null : (1.125em, 1.1)
								);

/**
 * Box Shadows
 */

$box-shadow-small: 5px;
$box-shadow-medium: 10px;
$box-shadow-large: 15px;

$box-shadow-light: rgba(25, 25, 25, 0.1);
$box-shadow-normal: rgba(25, 25, 25, 0.25);
$box-shadow-dark: rgba(25, 25, 25, 0.5);

/**
 * Animations & Transitions
 */

$btn-transistion-time: 300ms;
$link-transistion-time: 200ms;

// $breakpoints and $containers maps MUST contain same keys
$breakpoints: (
									x-small : 420px,
									small : 768px,
									medium : 992px,
									large : 1200px,
									full : 1890px
							);
