form {

	input[type="text"],
	input[type="date"],
	input[type="datetime"],
	input[type="email"],
	input[type="number"],
	input[type="search"],
	input[type="time"],
	input[type="url"],
	input[type="password"],
	input[type="tel"],
	textarea,
	select {
		box-sizing: border-box;
		display: block;
		padding: 6px 12px;
		margin-bottom:1rem;
		width:100%;
		line-height: 1.42857143;
		color: #555;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 4px;
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;

		&.error{
			border: 1px solid red;
		}
	}

	label {
		&.error{
			color: red;
		}
	}


}