.title-header{
	margin-top:2rem;
	h1 {
		color: map_get($grays, 'dark-grey');
		text-transform: uppercase;
		font-size: 2.8rem;
	}
	&__logos{
		display: flex;
		justify-content: space-around;
	}
}