@mixin screen-above($screen-size) {
  @media screen and (min-width: $screen-size) {
    @content;
  }
}

@mixin screen-below($screen-size) {
  @media screen and (max-width: ($screen-size - 1px)) {
    @content;
  }
}

@mixin screen-between($min-screen-size, $max-screen-size) {
  @media screen and (min-width: $min-screen-size) and (max-width: ($max-screen-size - 1px)) {
    @content;
  }
}
