
/*------------------------------------------------------------
>>>> BOOTSTRAP BUTTON MODS
-------------------------------------------------------------*/

.btn {
	border:0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	white-space: normal;
	padding: 10px 16px;
}

.btn--square{
	-webkit-border-radius: 0;
	-moz-border-radius:  0;
	border-radius:  0;
}

.btn:hover {
	background-color: map-get($colors,am-orange);
	color: #ffffff;
}

.btn-info {
	background-color: #ffffff;
	color: map-get($colors, am-blue);
}

.btn-default{
	border: 1px map-get($colors, am-blue) solid;
	background-color: #ffffff;
	color: map-get($colors, am-blue);
}

.btn-primary{
	background-color: map-get($colors, am-blue);
	color: #ffffff;
}

.btn-alert{
	background-color: map-get($colors, am-orange);
	color: #ffffff;
	&:hover{
		background-color: map-get($colors, am-blue);
	}
}


.btn-icon{
	margin-top:1rem;
	cursor:pointer;
	text-decoration: none;
	font-size: 18px;
	line-height: 1.6;
	letter-spacing: 1px;
	border:0;
	border-radius:  0;
	background-color: map-get($colors, am-blue);
	color: #ffffff;
	display:flex;
	align-items: stretch;
	&:hover{
		background-color: map-get($colors, am-orange);
	}
	.icon {
		background-color: map-get($colors, am-orange);
		min-width:50px;
		position: relative;
		svg {
			width: 28px;
			height:28px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			stroke: white;
			fill: white;
			path{
				fill: white;
			}
			circle{
				stroke: white;
			}
		}
	}
	.text{
		color: #ffffff;
		text-decoration: none;
		padding: 10px 16px;
		display: flex;
		justify-content: center; /* align horizontal */
		align-items: center; /* align vertical */
	}
}



.btn , .btn-lg {
	/*padding:14px 14px;*/
	/*font-size: 16px;*/
	letter-spacing: 1px;
}

.btn--center{
	display:block;
	min-width:300px;
	margin:auto;
}

.btn--full{
	display:block;
	width:100%;
	margin-bottom:1.8rem;
}

@media (max-width: 992px ) {
	.btn--center{
		min-width:initial;
		width:100%;
	}
}

