.feedback{

	.title-header{
		margin-top:0;

		@include screen-below(map-get($breakpoints, medium)) {
			margin-bottom:1rem;
			justify-content: space-between;
		}

		@include screen-below(map-get($breakpoints, small)) {
			//justify-content: flex-start;
		}

		img{
			max-height: 75px;
			margin:10px;
			@include screen-below(map-get($breakpoints, small)) {
				margin:20px;
				max-height: 50px;
			}
		}
	}

	&-form{
		&__label--department{
			text-transform: uppercase;
			font-weight: bold;
			line-height: 2.8rem;
		}

		&__recommend{
			padding:0.5rem;
			font-weight: lighter;
			display:none;
		}
	}

}